import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/popper.min'
import '@popperjs/core/lib/popper-lite.js'
import "bootstrap/dist/css/bootstrap.css"
import "./src/styles/fontawesome.all.min.css"
import "./src/styles/common.scss"
import "./src/styles/spacing.scss"
import "./src/styles/variables.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const onClientEntry = () => {
    if (!sessionStorage.getItem('reloaded')) {
        sessionStorage.setItem('reloaded', 'true');
        window.location.reload();
    }
};

export const onInitialClientRender = () => {
    if (window.location.hash === '') {
        window.scrollTo(0, 0);
    }
};